/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

.dark-layout .approver-table {
  thead {
    tr th:nth-child(1) {
      position: sticky;
      left: 0;
    }

    tr th:nth-child(2) {
      position: sticky;
      left: 83.98px;
    }

    tr th:nth-child(3) {
      position: sticky;
      left: 223.73px;
    }

    @media (max-width: 900px) {
      tr th:nth-child(1) {
        position: static;
      }

      tr th:nth-child(2) {
        position: static;
      }

      tr th:nth-child(3) {
        position: static;
      }
    }
  }

  tbody {
    tr td:nth-child(1) {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: #343a46 !important;
    }

    tr td:nth-child(2) {
      position: sticky;
      left: 83.98px;
      z-index: 1;
      background-color: #343a46 !important;
    }

    tr td:nth-child(3) {
      position: sticky;
      left: 231.84px;
      z-index: 1;
      background-color: #343a46 !important;
    }

    .td-table-separate td:nth-child(1) {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: #2d4e58 !important;
    }

    .td-table-separate td:nth-child(2) {
      position: sticky;
      left: 83.98px;
      z-index: 1;
      background-color: #2d4e58 !important;
    }

    .td-table-separate td:nth-child(3) {
      position: sticky;
      left: 231.84px;
      z-index: 1;
      background-color: #2d4e58 !important;
    }

    @media (max-width: 900px) {
      tr td:nth-child(1) {
        position: static;
      }

      tr td:nth-child(2) {
        position: static;
      }

      tr td:nth-child(3) {
        position: static;
      }

      .td-table-separate td:nth-child(1) {
        position: static;
      }

      .td-table-separate td:nth-child(2) {
        position: static;
      }

      .td-table-separate td:nth-child(3) {
        position: static;
      }
    }
  }
}

.approver-table {
  thead {
    tr th:nth-child(1) {
      position: sticky;
      left: 0;
    }

    tr th:nth-child(2) {
      position: sticky;
      left: 83.98px;
    }

    tr th:nth-child(3) {
      position: sticky;
      left: 223.73px;
    }
  }

  tbody {
    tr td:nth-child(1) {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: #fff;
    }

    tr td:nth-child(2) {
      position: sticky;
      left: 83.98px;
      z-index: 1;
      background-color: #fff;
    }

    tr td:nth-child(3) {
      position: sticky;
      left: 231.84px;
      z-index: 1;
      background-color: #fff;
    }

    .td-table-separate td:nth-child(1) {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: #afe9bd;
    }

    .td-table-separate td:nth-child(2) {
      position: sticky;
      left: 83.98px;
      z-index: 1;
      background-color: #afe9bd;
    }

    .td-table-separate td:nth-child(3) {
      position: sticky;
      left: 231.84px;
      z-index: 1;
      background-color: #afe9bd;
    }

    @media (max-width: 900px) {
      tr td:nth-child(1) {
        position: static;
      }

      tr td:nth-child(2) {
        position: static;
      }

      tr td:nth-child(3) {
        position: static;
      }

      .td-table-separate td:nth-child(1) {
        position: static;
      }

      .td-table-separate td:nth-child(2) {
        position: static;
      }

      .td-table-separate td:nth-child(3) {
        position: static;
      }
    }
  }
}

// Add by Ausada

/* --------------- blog styles --------------- */

.blog.style-1 {
  h2 {
    color: #3874ff;
  }
  .content-blogs {
    .blog_slider {
      position: relative;
      .swiper-button-next,
      .swiper-button-prev {
        width: 35px;
        height: 35px;
      }
      .swiper-button-next,
      .swiper-container-rtl .swiper-button-prev {
        right: 120px;
        top: -63px;
      }
      .swiper-button-prev,
      .swiper-container-rtl .swiper-button-next {
        right: 160px;
        left: auto;
        top: -63px;
      }
    }
    .blog_box {
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      .img {
        height: 300px;
        position: relative;
        &::after {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          opacity: 0.5;
          background-image: linear-gradient(to top, #000, transparent);
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: all 1s ease;
        }
      }
      .tags {
        position: absolute;
        top: 20px;
        left: 20px;
        z-index: 10;
        a {
          font-size: 11px;
          padding: 3px 7px;
          border-radius: 4px;
          background: var(--color-main-grad);
          color: #fff;
          text-transform: uppercase;
        }
      }
      .info {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 20px;
        z-index: 5;
        h6 {
          color: #fff;
          margin-bottom: 10px;
          border-bottom: 2px solid transparent;
          display: inline-block;
          line-height: 1.3;
          transition: all 0.3s ease;
          &:hover {
            a {
              color: #9993;
            }
          }
        }
        .auther {
          display: flex;
          span {
            padding: 0 10px;
            color: #fff;
            font-size: 12px;
            &:first-of-type {
              padding-left: 0;
              border-right: 1px solid #9993;
            }
            .auther-img {
              width: 15px;
              height: 15px;
              border-radius: 50%;
              object-fit: cover;
              margin-right: 3px;
            }
            i {
              font-size: 11px;
              margin-right: 4px;
            }
          }
        }
        .text {
          margin-top: 20px;
          font-size: 11px;
          color: #fff;
          display: none;
        }
      }
      &:hover {
        .img {
          img {
            transform: scale(1.3) rotate(-10deg);
          }
        }
        .info {
          h6 {
            border-color: #fff9;
          }
        }
      }
    }
  }
}

.testimonials.style-7 {
  position: relative;
  background-color: #f0eff5;
  .container {
    position: relative;
    z-index: 10;
  }
  &::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 80%;
    background: linear-gradient(to bottom, #fff, transparent);
    pointer-events: none;
  }
  .img_back {
    position: absolute;
    left: 0;
    top: 100px;
    width: 100%;
    height: 70%;
    object-fit: contain;
    pointer-events: none;
    z-index: 1;
  }
  .testi_qout {
    position: absolute;
    top: 150px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;
    pointer-events: none;
  }
  .testi-card {
    z-index: 2;
    .text {
      font-size: 24px;
      text-align: center;
    }
    .author {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
      .inf {
        p {
          color: #946ed6;
        }
        h6 {
          font-size: 18px;
          font-weight: bold;
        }
      }
    }
  }

  .get-qoute-banner {
    background-color: #fff;
    border-radius: 10px;
    padding: 0 50px;
    margin-top: 130px;

    .info {
      p {
        color: #666;
        margin-bottom: 5px;
      }
      h6 {
        font-size: 24px;
        font-weight: bold;
      }
    }
    .img {
      text-align: center;
      img {
        width: 200px;
        margin-top: -30px;
      }
    }
  }
}

// ------------ testimonials style-4 ------------
.testimonials.style-4 {
  overflow: hidden;

  .numbs {
    display: grid;
    justify-content: center;
    font-size: 20px;
    background-color: #eaeaea;
    border-radius: 5px;
    color: var(--color-blue4);
    margin-bottom: 20px;

    .num-card {
      .icon {
        height: 55px;
        display: inline-block;
        margin-bottom: 15px;
      }
      h2 {
        align-items: center;
        color: #22a6f2;
      }
      p {
        font-size: 11px;
        color: #666;
        margin-top: 5px;
      }
      &:not(:last-of-type) {
        padding-right: 50px;
        margin-right: 50px;
        border-right: 1px solid #9993;
      }
    }
  }

  .testi-cards {
    display: grid;
    justify-content: center;
    position: relative;
    padding: 0 5vw;
    .client_card {
      justify-content: center;
      width: 650px;
      position: relative;
      z-index: 10;
      border-radius: 20px;
      padding: 30px;
      display: flex;
      align-items: center;
      background-color: #fff;
      box-shadow: -4.104px 11.276px 38px 0px rgba(45, 42, 61, 0.1);
      margin: 20px;
      -webkit-animation: slide_up_down 2s ease-in-out infinite alternate both;
      animation: slide_up_down 2s ease-in-out infinite alternate both;
      .user_img {
        margin-right: 25px;
        flex-shrink: 0;
        img {
          width: 90px;
          height: 90px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      .inf_content {
        h5 {
          font-weight: bold;
          font-size: 15px;
          margin-bottom: 15px;
        }
        h6 {
          font-size: 12px;
          color: rgba(134, 129, 129, 0.2);
          text-transform: uppercase;
          span {
            text-transform: capitalize;
            color: #2e99f5;
          }
        }
      }
      &:nth-of-type(2) {
        right: -5vw;
      }
    }

    .testi-globe {
      position: absolute;
      top: -80px;
      left: 0;
      width: 110%;
      max-height: unset;
      max-width: unset;
      opacity: 0.2;
      -webkit-animation: rotate-center 100s linear infinite both;
      animation: rotate-center-rtl 100s linear infinite both;
    }
  }
}

/* --------------- solutions styles --------------- */
.portals.style-10 {
  h2 {
    span {
      color: #3874ff;
    }
  }
  .content-portals {
    display: flex;
    align-items: top;
    position: relative;
    //box-shadow: 0px -16px 32px 0px #0000000a;
    padding: 50px;
    .portals-title {
      z-index: -1;
      font-size: calc(8vw + 50px);
      text-transform: uppercase;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      font-weight: bold;
      line-height: 0.75;
      color: #f6f6f6;
      background-image: linear-gradient(to bottom, #908c8c, #e6dcdc2f);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .portal-card {
    display: flex;
    padding: 20px 20px;
    border-radius: 10px;
    background-color: #fff;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    h6 {
      font-size: 14px;
      font-weight: bold;
    }
    .icon {
      img {
        filter: invert(54%) sepia(58%) saturate(5618%) hue-rotate(228deg) brightness(99%) contrast(92%);
      }
    }
    &:hover {
      background-color: #2e99f5;
      color: #fff;
      box-shadow: 10px 10px 30px #0004;
      .icon {
        img {
          filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(135deg) brightness(107%) contrast(106%);
        }
      }
    }
  }
}

/* --------------- Activities -us style-9 --------------- */

.choose-us.style-9 {
  .nav {
    justify-content: space-between;
    border-bottom: 1px solid #9993;
    .nav-link {
      color: #fff;
      font-weight: bold;
      text-transform: capitalize;
      padding: 20px 15px;
      border-radius: 0;
      img {
        width: 30px;
        height: 30px;
        object-fit: contain;
        margin-inline-end: 20px;
        filter: invert(26%) sepia(39%) saturate(6305%) hue-rotate(246deg) brightness(99%) contrast(105%);
      }
      &.active {
        background: transparent;
        border-bottom: 1px solid #3874ff;
      }
    }
  }
  .feat-content {
    padding-top: 40px;
    position: relative;
    .img {
      height: 400px;
      border-radius: 10px;
      overflow: hidden;
    }
    .info {
      .icon {
        width: 80px;
        margin-bottom: 20px;
      }
      h2 {
        font-size: 30px;
        color: #3874ff;
        margin-bottom: 15px;
      }
      p {
        font-size: 16px;
        color: #666;
      }
    }

    .play_icon {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background-color: #fff;
      color: #3874ff;
      line-height: 80px;
      text-align: center;
      font-size: 20px;
      position: absolute;
      top: 200px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;
    }
  }
}

/* --------------- projects style-13 --------------- */
.projects.style-13 {
  h6 {
    color: #3874ff;
  }
  position: relative;

  &::before {
    position: absolute;
    content: '';
    left: calc(50% - 300px);
    top: 0;
    width: 400px;
    height: 400px;
    // background: linear-gradient(to bottom, #fff9ef, transparent);
    border-radius: 50%;
  }

  .project-card {
    .img {
      position: relative;

      height: 300px;
      border-radius: 15px;
      overflow: hidden;
      justify-items: center;

      .info {
        position: absolute;
        left: 30px;
        bottom: -200px;
        width: calc(100% - 60px);
        padding: 20px;
        background-color: #fff;
        border-radius: 15px;
        overflow: hidden;
        z-index: 10;
        transform: translateY(150%);
        transition: all 0.4s ease;
        &::before {
          position: absolute;
          content: '';
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background-image: url(../img/projects/project_13_pattern.png);
          background-size: cover;
          background-repeat: no-repeat;
          z-index: -1;
          opacity: 0.1;
        }
        p {
          color: #999;
          margin-bottom: 5px;
        }
        h6 {
          font-size: 20px;
          font-weight: bold;
        }
        .logo {
          max-width: 140px;
        }
      }
    }

    &:hover {
      .img {
        .info {
          transform: translateY(0);
        }
      }
    }
  }
  .links {
    margin-top: 80px;
    .links-content {
      border-bottom: 1px solid #9993;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      a {
        width: 125px;
        margin: 20px 0;
      }
    }
    p {
      margin-top: 20px;
    }
  }
}

.skeleton {
  margin-bottom: 8px;
  height: 20px;
  background-color: grey;
  opacity: 0.1;
  border-radius: 999px;
  animation-name: skeleton;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.skeleton-sm {
  width: 100px;
}
.skeleton-md {
  width: 150px;
}
.skeleton-lg {
  width: 400px;
}
.skeleton-xl {
  width: 500px;
}
.skeleton-2xl {
  width: 600px;
}

@keyframes skeleton {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 0.3;
  }
}
