//  ================================================================================================
//  ? TIP:  It is recommended to use this file for overriding bootstrap variables.
//  ================================================================================================
//$primary: #556ee6;
$primary: #3874ff;
$success: #34c38f;
//$success: #0ab39c;

// $font-family-sans-serif: 'Mulish', Helvetica, Arial, serif !default;
// $font-family-monospace: 'Mulish', Helvetica, Arial, serif !default;
