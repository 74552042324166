@mixin nav-justified {
  width: 100%;

  .nav-item {
    float: none;
  }

  .nav-link {
    text-align: center;
    margin-bottom: 5px;
  }

  > .dropdown .dropdown-menu {
    top: auto;
    left: auto;
  }

  @include media-breakpoint-up(sm) {
    .nav-item {
      display: block;
      width: 1%;
    }
    .nav-link {
      margin-bottom: 0;
    }
  }
}

@mixin nav-tabs-justified {
  .nav-link {
    margin-right: 0;
    border-radius: $border-radius;
  }

  @include media-breakpoint-up(sm) {
    .nav-link.active,
    .nav-link.active:hover,
    .nav-link.active:focus {
      border-bottom-color: transparent;
    }
  }
}
